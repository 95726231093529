$(document).ready(function(){
    $(".tx-nw-citavi-fe .heading").click(function(){
        var target = $(this).attr("data-target");
        
        // Nur innerhalb von .tx-nw-citavi-fe andere Panels schließen
        $(this).closest(".tx-nw-citavi-fe").find(".panel-collapse")
            .not(target).slideUp().removeClass("in");
        $(this).closest(".tx-nw-citavi-fe").find(".heading")
            .not(this).addClass("collapsed").attr("aria-expanded", "false");
        
        // Aktuelles Panel umschalten
        $(target).slideToggle().toggleClass("in");
        $(this).toggleClass("collapsed");
        $(this).attr("aria-expanded", $(this).hasClass("collapsed") ? "false" : "true");
    });
});
