import Glide from '@glidejs/glide';

const sliders = document.querySelectorAll('.glide');

sliders.forEach(item => {
  const sliderType = item.dataset.slidertype;
  const glide = new Glide(item, {
    type: 'carousel',
    startAt: 0,
    keyboard: 1,
    perTouch: 1,
    perView: sliderType == 0 ? 1 : 6,
    autoplay: 6000,
    hoverpause: true,
    gap: sliderType == 0 ? 0 : 0,
    animationDuration: 400,
    animationTimingFunc: 'ease-in-out',
    peek: { before: 0, after: 0 },
    breakpoints: {
      768: {
        perView: sliderType == 0 ? 1 : 2,
      },
      992: {
        perView: sliderType == 0 ? 1 : 4,
      }
    }
  });

  glide.mount();

});
