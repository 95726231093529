// console.log($('').selectize());

require('./Components/variables.js');
require('./Components/helper');

require('./Components/backdrop.js');

require('./Components/menu.js');
require('./Components/breadcrumb');

require('./Components/lightbox.js');

require('./Components/geolangoverlay');
require('./Components/overlay.js');

require('./Components/footer_map');
require('./Components/buttonanimation.js');

require('./Components/glide.js');

require('./Components/slick.js');
require('./Components/run.js');

require('./Components/citavi-accordion.js');
//require('./Components/stickyHeader.js');

//Bootstrap 
//require('./Vendors/bootstrap/js/index.esm.js');
import { Collapse, Popover, Modal, Dropdown } from 'bootstrap';

//
// klu_cce
//

require('./../../../../../klu_cce/Resources/Private/Assets/JavaScript/components/teaser');
require('./../../../../../klu_cce/Resources/Private/Assets/JavaScript/components/currency');