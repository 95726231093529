$(document).ready(function () {

    var autoplaySpeed = 10000;
    var speed = 1400;

    $('.inmedium-slider .inmedium-slider-image').slick({
        asNavFor: '.inmedium-slider .inmedium-slider-content',
        arrows: false,
        autoplaySpeed: autoplaySpeed,
        speed: speed
    });
    $('.inmedium-slider .inmedium-slider-content').slick({
        asNavFor: '.inmedium-slider .inmedium-slider-image',
        autoplay: true,
        appendArrows: '.inmedium-slider .nav',
        prevArrow: '<button type="button" data-role="none" class="slick-prev" aria-label="Previous" tabindex="0" role="button"><svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50"><path fill="#FFFFFF" d="M0,5v40c0,2.8,2.2,5,5,5h45V5c0-2.8-2.2-5-5-5H5C2.2,0,0,2.2,0,5z M30,32.5L27.8,34L19,25l8.8-9l2.2,1.5 L23.4,25L30,32.5z" /></svg></button>',
        nextArrow: '<button type="button" data-role="none" class="slick-prev" aria-label="Previous" tabindex="0" role="button"><svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50"><path fill="#FFFFFF" d="M50,5v40c0,2.8-2.2,5-5,5H0V5c0-2.8,2.2-5,5-5h40C47.8,0,50,2.2,50,5z M20,32.5l2.2,1.5l8.8-9l-8.8-9L20,17.5 l6.6,7.5L20,32.5z" /></svg></button>',
        autoplaySpeed: autoplaySpeed,
        speed: speed
    });

    $('.videoslider-slides.on').each(function () {

        var navId = $(this).next('.videoslider-nav').attr('id');
        var autoplay = false;

        if ($(this).hasClass('slide')) {
            var autoplay = true;
        };

        $(this).slick({
            pauseOnHover: true,
            focusOnSelect: true,
            draggable: false,
            autoplay: autoplay,
            autoplaySpeed: 10000,
            speed: 1400,
            appendArrows: '#' + navId,
            prevArrow: '<button type="button" data-role="none" class="slick-prev" aria-label="Previous" tabindex="0" role="button"><svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50"><g id="Gruppe_430" data-name="Gruppe 430" transform="translate(-1535 -1580)"><path id="Rechteck_271" data-name="Rechteck 271" d="M5,0H45a5,5,0,0,1,5,5V50a0,0,0,0,1,0,0H5a5,5,0,0,1-5-5V5A5,5,0,0,1,5,0Z" transform="translate(1535 1580)" fill="#b7a364"/><path id="Pfad_312" data-name="Pfad 312" d="M9,0l2,2L4.5,9.5,11,17,9,19,0,9.5Z" transform="translate(1554 1596)" fill="#fff"/></g></svg></button>',
            nextArrow: '<button type="button" data-role="none" class="slick-prev" aria-label="Previous" tabindex="0" role="button"><svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50"><g id="Gruppe_429" data-name="Gruppe 429" transform="translate(-1594 -1580)"><path id="Rechteck_272" data-name="Rechteck 272" d="M5,0H45a5,5,0,0,1,5,5V45a5,5,0,0,1-5,5H0a0,0,0,0,1,0,0V5A5,5,0,0,1,5,0Z" transform="translate(1594 1580)" fill="#b7a364"/><path id="Pfad_313" data-name="Pfad 313" d="M2,0,0,2,6.5,9.5,0,17l2,2,9-9.5Z" transform="translate(1614 1596)" fill="#fff"/></g></svg></button>'

        });
    });
});

$(document).ready(function () {

    $('.textboxes').each(function () {

        var navId = $(this).next('.textbox-nav').attr('id');
        var autoplay = false;

        if ($(this).hasClass('slide')) {
            var autoplay = true;
        };

        $(this).slick({
            pauseOnHover: true,
            focusOnSelect: true,
            draggable: false,
            autoplay: autoplay,
            autoplaySpeed: 10000,
            speed: 1400,
            appendArrows: '#' + navId,
            prevArrow: '<button type="button" data-role="none" class="slick-prev btn btn-secondary" aria-label="Previous" tabindex="0" role="button"><svg class="align-self-center d-flex my-1" width="1rem" height="1rem" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.414 8.24201L7.364 12.192C7.54616 12.3806 7.64695 12.6332 7.64467 12.8954C7.6424 13.1576 7.53723 13.4084 7.35182 13.5938C7.16641 13.7792 6.9156 13.8844 6.6534 13.8867C6.3912 13.889 6.1386 13.7882 5.95 13.606L0.293001 7.94901C0.199815 7.85635 0.125866 7.74619 0.0754047 7.62486C0.024943 7.50353 -0.00103417 7.37341 -0.00103416 7.24201C-0.00103414 7.1106 0.024943 6.98049 0.0754048 6.85915C0.125867 6.73782 0.199815 6.62766 0.293001 6.53501L5.95 0.878006C6.04225 0.782496 6.15259 0.706314 6.2746 0.653905C6.3966 0.601496 6.52782 0.57391 6.6606 0.572756C6.79338 0.571602 6.92506 0.596904 7.04796 0.647185C7.17085 0.697465 7.2825 0.771718 7.3764 0.865611C7.47029 0.959505 7.54454 1.07116 7.59482 1.19405C7.6451 1.31695 7.67041 1.44863 7.66925 1.58141C7.6681 1.71419 7.64051 1.84541 7.5881 1.96741C7.53569 2.08941 7.45951 2.19976 7.364 2.29201L3.414 6.24201L13 6.24201C13.2652 6.24201 13.5196 6.34736 13.7071 6.5349C13.8946 6.72244 14 6.97679 14 7.24201C14 7.50722 13.8946 7.76158 13.7071 7.94911C13.5196 8.13665 13.2652 8.24201 13 8.24201L3.414 8.24201Z" fill="white"/></svg></button>',
            nextArrow: '<button type="button" data-role="none" class="slick-prev btn btn-secondary" aria-label="Previous" tabindex="0" role="button"><svg class="align-self-center d-flex my-1" width="1rem" height="1rem" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.586 5.65643L6.636 1.70643C6.45384 1.51783 6.35305 1.26523 6.35533 1.00303C6.3576 0.740833 6.46277 0.49002 6.64818 0.304612C6.83359 0.119204 7.0844 0.014035 7.3466 0.0117566C7.6088 0.00947813 7.8614 0.110273 8.05 0.292431L13.707 5.94943C13.8002 6.04208 13.8741 6.15224 13.9246 6.27358C13.9751 6.39491 14.001 6.52502 14.001 6.65643C14.001 6.78784 13.9751 6.91795 13.9246 7.03928C13.8741 7.16062 13.8002 7.27078 13.707 7.36343L8.05 13.0204C7.95775 13.1159 7.84741 13.1921 7.7254 13.2445C7.6034 13.2969 7.47218 13.3245 7.3394 13.3257C7.20662 13.3268 7.07494 13.3015 6.95205 13.2513C6.82915 13.201 6.7175 13.1267 6.62361 13.0328C6.52971 12.9389 6.45546 12.8273 6.40518 12.7044C6.3549 12.5815 6.3296 12.4498 6.33075 12.317C6.3319 12.1842 6.35949 12.053 6.4119 11.931C6.46431 11.809 6.54049 11.6987 6.636 11.6064L10.586 7.65643L1 7.65643C0.734784 7.65643 0.48043 7.55107 0.292893 7.36354C0.105357 7.176 0 6.92165 0 6.65643C0 6.39121 0.105357 6.13686 0.292893 5.94932C0.48043 5.76179 0.734784 5.65643 1 5.65643L10.586 5.65643Z" fill="white"/></svg></button>'
        });
    });
});
