/**
 * Burger menu
 */
document.getElementById("burger-icon")?.addEventListener("click", function() {
    // Toggle für Burger-Menü-Icon
    this.classList.toggle("active");
    this.querySelectorAll(".top, .middle, .bottom").forEach(el => el.classList.toggle("active"));

    // Toggle für Header und andere Elemente
    const header = document.getElementById("header");
    requestAnimationFrame(() => {
        header?.classList.toggle("bg-secondary");
        header?.classList.toggle("min-vh-100");
        header?.classList.toggle("h-auto");
        header?.classList.toggle("transition-all-ease");
        header?.classList.toggle("border-radius-l");
    });
    document.getElementById("breadcrumb")?.classList.toggle("d-none");
    document.getElementById("highlighted-items")?.classList.toggle("d-none");
    document.getElementById("megaMenu")?.classList.toggle("d-block");
    document.getElementById("mega-menu-overlay")?.classList.toggle("d-block");
    document.getElementById("mega-menu-search")?.classList.toggle("d-none");
    document.getElementById("mega-menu-search")?.classList.toggle("d-md-block");
    document.getElementById("mega-menu-search-mobile")?.classList.toggle("d-md-none");
    document.getElementById("mega-menu-search-mobile")?.classList.toggle("d-block");
    document.getElementById("logo")?.classList.toggle("flex-md-grow-0");

    const megaMenu = document.getElementById("megaMenu");
    const html = document.documentElement;  // <html> Tag
    const body = document.body;

    // Wenn das Menü geöffnet wird
    if (megaMenu?.classList.contains("d-block")) {
        html.style.overflow = "hidden"; // Verhindert das Scrollen der Seite
        body.style.overflow = "hidden"; // Verhindert das Scrollen des Body
    } else {
        // Wenn das Menü geschlossen wird, Scrollen auf der Seite wieder erlauben
        html.style.overflow = "auto"; // Scrollen der Seite wieder aktivieren
        body.style.overflow = "auto"; // Scrollen des Body wieder aktivieren
    }
});

// Überwacht Änderungen der Höhe des Headers
const header = document.getElementById("header");
const resizeObserver = new ResizeObserver(() => {
    const megaMenu = document.getElementById("megaMenu");
    const html = document.documentElement;
    const body = document.body;

    // Überprüfen, ob der Header größer als der Bildschirm ist
    if (header && header.scrollHeight > window.innerHeight) {
        header.style.overflowY = "scroll"; // Header wird scrollbar
        header.style.maxHeight = `${window.innerHeight}px`; // Maximale Höhe für den Header setzen
    } else {
        header.style.overflowY = "auto"; // Header bleibt scrollbar
        header.style.maxHeight = "none"; // Keine Begrenzung der Höhe
    }
});

// Beobachtet den Header auf Größenänderungen
if (header) {
    resizeObserver.observe(header);
}


// Mega-Menu
document.addEventListener("DOMContentLoaded", function () {
    document.querySelectorAll(".mega-quick-links").forEach(quickLinks => {
        quickLinks.style.display = "block";
    });

    document.querySelectorAll(".mega-menu-category-box").forEach(box => {
        box.classList.remove("show");
        box.style.display = "none";
    });

    document.querySelectorAll(".mega-menu-link").forEach(link => {
        link.addEventListener("click", function (e) {
            e.preventDefault();

            // Entferne "active" von allen anderen Links
            document.querySelectorAll(".mega-menu-link").forEach(link => link.classList.remove("active"));

            this.classList.add("active");

            let category = this.getAttribute("data-category");
            let activeBoxes = document.querySelectorAll(`.mega-menu-category-box[data-category="${category}"]`);
            let megaQuickLinks = document.querySelectorAll(".mega-quick-links");
            let isVisible = Array.from(activeBoxes).some(box => box.classList.contains("show"));

            if (isVisible) {
                activeBoxes.forEach(box => {
                    box.classList.remove("show");
                    box.style.display = "none";
                });

                setTimeout(() => {
                    if (!document.querySelector(".mega-menu-category-box.show")) {
                        requestAnimationFrame(() => {
                            megaQuickLinks.forEach(quickLinks => quickLinks.style.display = "block");
                        });
                    }
                }, 350);

                this.classList.remove("active");

                return;
            }

            megaQuickLinks.forEach(quickLinks => {
                quickLinks.style.display = "none";
            });

            document.querySelectorAll(".mega-menu-category-box.show").forEach(box => {
                box.classList.remove("show");
                box.style.display = "none";
            });

            activeBoxes.forEach((box, index) => {
                setTimeout(() => {
                    box.style.display = "block";
                    setTimeout(() => {
                        requestAnimationFrame(() => {
                            box.classList.add("show");
                        });
                    }, 10);
                }, index * 50);
            });
        });
    });
});


/**
 * Zielgruppennavigation
 */
$(function () {
    $('.top-bar--navigation li.toggle').on('click', 'span.toggle', function(){
        $('.dropdown-menu').click(function(event){
            event.stopPropagation();
        });
        $(this).next('ul').animate({
            left: "0"
        }, 250, function() {
            var wrapper = $(this);
            var back = $(this).find('.toggle-back');
            back.click(function(){
                wrapper.animate({
                    left: "100%"
                }, 250);
            })
        });
    });
});

/**
 * Scrollbar
 */

(function($){
    $('.level-4-menu').each(function(element) {

        $(this).enscroll({
            // showOnHover: true,
            verticalTrackClass: 'scroll-track',
            verticalHandleClass: 'scroll-handle',
            zIndex: 10,
            easingDuration: 100
        });

    });
})(jQuery);


/**
 * Main Menu
 */

$(function () {
    $('.level-2').on('click', '.level-2-toggle', function (event) {

        /**
         * Var
         */
        var parent = $(event.target).parent('.level-2');

        /**
         * Close other
         *
         */
        parent.siblings('.level-2.open').removeClass('open').find('.level-4').removeClass('open').find('.level-5.open').removeClass('open').find('.level-5-menu').collapse('hide');


        /**
         * Toggle
         */
        if (parent.hasClass('open')) {
            parent.removeClass('open').find('.level-4').removeClass('open').find('.level-5.open').removeClass('open').find('.level-5-menu').collapse('hide');
            toggleBackDrop(0);
        } else {
            parent.addClass('open');
            toggleBackDrop(1,1040);
        }

        /**
         * Active mode
         */

        if($('.level-4.active').length) {

            var childParentActive = $('.level-4.active').addClass('open');
            var parentpositionActive = childParentActive.parents('.level-2-menu').offset().top;
            var childmenuActive = childParentActive.find('.level-4-menu');
            var childpositionActive = childParentActive.parents('.level-3').offset().top;
            childmenuActive.css('top', parentpositionActive - childpositionActive);
        }

        if($('.level-5-active').length) {
            $('.level-5-active').parents('.level-5').addClass('open').find('.level-5-menu').collapse('toggle');
        }

        /**
         * Level-4
         */

        $('.level-4').off().on('click', '.level-4-toggle', function (event) {
            /**
             * Var
             */
            var childParent = $(event.target).parent('.level-4');

            /**
             * Toggle
             */
            childParent.siblings('.level-4.open').removeClass('open');
            $('.level-2-menu').find('.level-4.open').removeClass('open').find('.level-5.open').removeClass('open').find('.level-5-menu').collapse('hide');



            if (childParent.hasClass('open')) {
                childParent.removeClass('open');
            } else {
                childParent.addClass('open');

                /**
                 * Position
                 */
                var parentposition = childParent.parents('.level-2-menu').offset().top;
                var childmenu = childParent.find('.level-4-menu');
                var childposition = childParent.parents('.level-3').offset().top;
                childmenu.css('top', parentposition - childposition);
            }
        });

        $('.level-4-menu').on('click', '.toggle-back-4', function (event) {
            $(event.target).parents('.level-4.open').removeClass('open').find('.level-5.open').removeClass('open').find('.level-5-menu').collapse('hide');
        });


        /**
         * Level-5
         */

        $('.level-5').off().on('click', '.level-5-toggle', function (event) {
            var childParent = $(event.target).parent('.level-5');

            childParent.find('.level-5-menu').collapse('toggle');
            if (childParent.hasClass('open')) {
                childParent.removeClass('open');
            } else {
                childParent.addClass('open');
            }
        });

        // $('.level-5').off().on('click', '.level-5-toggle', function (event) {
        //     /**
        //      * Var
        //      */
        //     var childParent = $(event.target).parent('.level-5');
        //
        //     /**
        //      * Toggle
        //      */
        //     childParent.siblings('.level-5.open').removeClass('open');
        //     $('.level-3-menu').find('.level-5.open').removeClass('open');
        //
        //     if (childParent.hasClass('open')) {
        //         childParent.removeClass('open');
        //     } else {
        //         childParent.addClass('open');
        //     }
        // });
        //
        // $('.level-5-menu').on('click', '.toggle-back-5', function (event) {
        //     $(event.target).parents('.level-5.open').removeClass('open');
        // });




        /**
         * Detect outside click
         */
        $('html').off().on('click', function (event) {
            if (
                !$(event.target).is('.level-2-toggle') && !$(event.target).is('.level-2-menu') && !$(event.target).is('.level-2-menu *') && !$(event.target).is('.level-2')
            ) {
                if (parent.hasClass('open')) {
                    parent.removeClass('open').find('.level-4').removeClass('open').find('.level-5.open').removeClass('open').find('.level-5-menu').collapse('hide');
                    toggleBackDrop(0);
                }
            }
        });
    });
});